import { Component } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { RouterLink } from "@angular/router";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "app-shared-header-component",
    templateUrl: "./shared-header.component.html",
    styles: [
        `::ng-deep .mat-mdc-menu-content { padding: 0 !important; }`
    ],
    standalone: true,
    imports: [MatButtonModule, MatTooltipModule, MatIconModule, MatMenuModule, RouterLink, MatDividerModule]
})
export class SharedHeaderComponent {

}
