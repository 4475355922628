<a href="https://help.agorify.com/" matTooltip="Help center" target="_blank" mat-icon-button>
  <mat-icon>help</mat-icon>
</a>
<button mat-icon-button matTooltip="Agorify modules" [matMenuTriggerFor]="switchMenu" aria-label="Switch module button">
  <mat-icon>view_module</mat-icon>
</button>
<mat-menu #switchMenu="matMenu" yPosition="below" direction="left">
  <button mat-menu-item routerLink="/event"> Organizer </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/exhibitor"> Exhibitor </button>
</mat-menu>
<button mat-icon-button matTooltip="User settings" routerLink="/admin/account" aria-label="My account button">
  <mat-icon>person</mat-icon>
</button>
